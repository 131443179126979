/* General body styling */
body {
  background-color: #f8f9fa;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin: 0;
  min-height: 100vh; /* Ensure the body spans the viewport height */
  display: flex;
  flex-direction: column; /* Enable flexbox for body */
}

/* Root app container */
#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure the root container spans the viewport height */
}

/* Main App layout */
.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
}

/* Content container */
.content {
  flex: 1; /* Push footer to the bottom for short content */
}

/* Footer styling */
footer {
  text-align: center;
  padding: 10px;
  font-size: 0.9rem;
  color: #6c757d;
}

/* Container for specific pages */
.container {
  max-width: 500px;
  margin-top: 50px;
  flex: 1; /* Allows the content to grow and push the footer down */
}

/* Form styling */
.form-label {
  font-weight: bold;
}

.form-container {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Button customization */
.btn-custom:disabled {
  color: white;
  opacity: 1; /* Override the default disabled styling to keep it looking the same */
  cursor: not-allowed; /* Show a 'not-allowed' cursor when the button is disabled */
}

.btn-custom i {
  margin-left: 5px;
}

/* Subtitle styling */
.subtitle-component {
  color: #6c757d;
  font-size: 1rem;
  padding: 15px 0;
  text-align: center;
}

.subtitle-demo-form {
  color: #6c757d;
}

/* Job title styling */
.jobtitle {
  color: #6c757d;
}

/* Logo container */
.logo-container {
  text-align: center;
  margin-bottom: 20px;
}

.logo-container img {
  width: 25%;
}

/* Title section */
.title-section {
  background-image: url('https://www.teamsignal.com/hubfs/HC0147_Day2_Neighborhood_0085.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 25px 0;
  text-align: center;
  color: white;
  position: relative;
  z-index: 1;
  margin-bottom: 20px;
}

.title-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

.content-section {
  padding: 6rem 0rem;
}

@media (max-width: 768px) {
  .content-section {
    padding: 4rem 0rem;
  }
}

/* Disclaimer styling */
.disclaimer {
  font-size: 0.8rem;
  color: #6c757d;
}

.disclaimer a {
  color: #6c757d; /* Same color as the rest of the disclaimer text */
  font-weight: normal; /* Keep link bold */
}

.disclaimer a:hover {
  color: #343a40; /* Slightly darker on hover */
}

/* Prevents the row from overflowing the container */
.prevent-overflow {
  overflow-x: hidden;
  max-width: 100%;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
