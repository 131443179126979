.pilotNavbar {
  height: 6rem;
}

.pilotNavbar .nav-link {
  font-weight: bold;
}

.pilotNavbar .nav-link {
  font-weight: bold;
}

.app-navbar-dropdown {
  font-weight: bold;
}
