/* Stats Section Styles */
.stats-section {
  background-color: black;
  color: white;
  padding: 150px 20px;
}

.stats-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0 auto;
  max-width: 1200px;
}

.stats-section-title {
  text-align: center;
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 2rem;
  padding: 0rem 0rem 3rem;
}

.stats-columns {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
}

.stats-column {
  flex: 1;
  max-width: 30%;
  min-width: 250px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Ensures content inside each column is centered */
}

.stats-heading {
  display: flex;
  align-items: baseline;
  justify-content: center;
  font-weight: 600;
  margin: 0;
  font-size: 5rem; /* Font size for the number */
  color: #b1fcf8;
}

.stats-subheading-emphasis {
  color: #b1fcf8;
}

.percentage {
  font-size: 3rem; /* Font size for the percentage sign */
  margin-left: 5px;
}

.stats-subheading {
  text-align: center;
  font-size: 1.25rem;
  margin: 10px 0 0;
  padding: 10px 25px;
}

/* Responsive Design for Mobile */
@media (max-width: 768px) {
  .stats-section {
    padding: 100px 20px;
  }

  .stats-section-title {
    text-align: center; /* Center the title on mobile */
    font-size: 2rem;
    padding: 0rem 0rem 1rem;
  }

  .stats-columns {
    flex-direction: column; /* Stack columns vertically */
    align-items: center; /* Center columns in the container */
  }

  .stats-column {
    max-width: 100%; /* Allow columns to take full width */
    margin: 20px 0; /* Add spacing between stacked columns */
    text-align: center;
  }

  .stats-heading {
    font-size: 3.5rem;
  }

  .percentage {
    font-size: 2.5rem;
  }
}
