/* Ensure the container height is fixed */
.conversation-container {
  max-width: 900px;
  height: 90vh; /* Fixed height */
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 20px;
}

@media (max-width: 768px) {
  .conversation-container {
    max-width: 100vw;
    padding: 10px; /* Reduce padding on smaller screens */
  }
}

.messages-container {
  flex: 1;
  overflow-y: auto; /* Allow scrolling for messages */
  padding: 10px;
  display: flex;
  flex-direction: column;
}

/* Wrapper for individual message and timestamp */
.message-wrapper {
  display: flex;
  flex-direction: column;
  flex-shrink: 0; /* Prevent shrinking when content grows */
  margin-bottom: 8px; /* Default margin for all screens */
  padding: 10px;
}

@media (max-width: 768px) {
  .message-wrapper {
    margin-top: 16px; /* Increased spacing for mobile */
    margin-bottom: 16px; /* Ensure clear separation for long messages */
  }
}

/* Left-aligned (incoming message) */
.left-message {
  align-items: flex-start;
  width: 100%; /* Allow full-width adjustments */
}

/* Right-aligned (outgoing message) */
.right-message {
  align-items: flex-end;
  width: 100%; /* Allow full-width adjustments */
}

/* General message bubble styling */
.message-bubble {
  max-width: 70%; /* Prevent bubbles from getting too wide */
  padding: 8px 12px; /* Adjusted padding for cleaner look */
  border-radius: 20px;
  font-size: 14px;
  position: relative;
  word-wrap: break-word; /* Break long words */
  overflow-wrap: break-word; /* For URLs and unbroken text */
  white-space: pre-wrap; /* Preserve whitespace and line breaks */
  line-height: 1.5; /* Improve readability */
  /* min-height: 40px; /* Ensure consistent bubble height for small messages */
}

/* Gray bubble for incoming messages (aligned to the left) */
.gray-bubble {
  background-color: #e5e5ea;
  color: black;
  border-radius: 20px 20px 20px 0; /* iOS-style rounded corners */
}

/* Blue bubble for outgoing messages (aligned to the right) */
.blue-bubble {
  background-color: #007aff;
  color: white;
  border-radius: 20px 20px 0 20px; /* iOS-style rounded corners */
}

/* Timestamp styling for gray bubble (left-aligned) */
.gray-bubble-timestamp {
  font-size: 10px;
  margin-top: 2px;
  color: #999;
  text-align: left; /* Align text to the left */
}

/* Timestamp styling for blue bubble (right-aligned) */
.blue-bubble-timestamp {
  font-size: 10px;
  margin-top: 2px;
  color: #999;
  text-align: right; /* Align text to the right */
}

/* Input field styling */
.message-input-container {
  display: flex;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #ccc;
  background-color: white;
  position: sticky; /* Ensure it stays at the bottom */
  bottom: 0;
}

/* Input field */
.message-input {
  flex: 1;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 20px;
  outline: none;
  margin-right: 10px;
}

/* Send button */
.message-send-button {
  padding: 10px 15px;
  background-color: #007aff;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
}

.message-send-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Message content */
.message-content {
  margin-bottom: 0rem; /* Default for all screens */
}

@media (max-width: 768px) {
  .message-content {
    margin-bottom: 0.5rem; /* Add slight bottom margin for mobile */
  }
}
