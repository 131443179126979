/* Footer CSS */
.footer {
  background-color: #343a40; /* Dark background color */
  padding: 1rem 0;
  margin-top: auto; /* Push the footer to the bottom when content is short */
}

.footer-container {
  max-width: 500px; /* Restrict content width */
  margin: 0 auto; /* Center the content horizontally */
  display: flex;
  flex-direction: column; /* Default column layout for mobile */
  align-items: center;
  text-align: center;
}

@media (min-width: 768px) {
  .footer-container {
    flex-direction: row; /* Row layout for desktop */
    justify-content: space-between; /* Space out items horizontally */
    align-items: center; /* Center items vertically */
    text-align: left; /* Align text left for consistency */
  }
}

.footer-logo-img {
  height: 40px;
  width: auto;
  margin-bottom: 1rem; /* Space below the logo for mobile view */
}

@media (min-width: 768px) {
  .footer-logo-img {
    margin-bottom: 0; /* Remove margin for desktop view */
  }
}

.footer-text {
  margin-bottom: 1rem; /* Space below the text for mobile view */
}

.footer-text p {
  margin-bottom: 0rem; /* Space below the text for mobile view */
}

@media (min-width: 768px) {
  .footer-text {
    margin-bottom: 0; /* Remove margin for desktop view */
  }
}

.footer-links {
  display: flex;
  gap: 1rem; /* Space between links */
}

.footer-link {
  color: #fff;
  text-decoration: none;
}

.footer-link:hover {
  text-decoration: underline;
}

/* Layout adjustments for footer positioning */
body {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure the body takes up the full viewport height */
  margin: 0; /* Remove default body margin */
}

main {
  flex: 1; /* Allow the main content to grow and push the footer down */
}
