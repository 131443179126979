/* Why InstaHear Section Styles */
.why-instahear-section {
  padding: 4rem 1.25rem;
}

.instahear-white-bg {
  background-color: #fff;
}

.why-instahear-section-h3 {
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 3rem;
}

.experiences-carousel-avatar {
  width: 64px;
  height: 64px;
  min-height: 64px;
  margin-bottom: 16px;
  border-radius: 50%;
  overflow: hidden;
  border: 4px solid #ffffff;
}

/* Button container */
.why-instahear-button-container {
  text-align: left;
}

.why-instahear-button-container button {
  width: auto; /* Prevent full-width button */
  padding: 0.75rem 2rem;
  font-size: 1rem;
}

/* Mobile-specific styles for the experiences carousel */
@media (max-width: 768px) {
  .why-instahear-section {
    padding: 2rem 1.25rem;
  }

  .experiences-carousel {
    flex-direction: column; /* Stack items vertically */
  }

  .experiences-carousel-item {
    display: flex; /* Use flexbox for two-column layout */
    align-items: center; /* Align items vertically */
    width: 100%; /* Full width for each item */
    margin-bottom: 20px; /* Add spacing between items */
  }

  .experiences-carousel-avatar {
    flex: 0 0 64px; /* Fix the size of the image */
    margin-right: 16px; /* Add spacing between image and text */
  }

  .experiences-carousel-content {
    flex: 1; /* Allow text to take the remaining space */
    text-align: left; /* Align text to the left */
  }

  .why-instahear-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px; /* Add spacing above the button */
  }
  .why-instahear-section-h3 {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 3rem;
  }
}
