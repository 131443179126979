/* FAQ Section Styles */
.faq-section {
  display: flex;
  flex-direction: column;
}

.faq-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0rem 2rem;
}

.faq-sidebar {
  flex: 0 0 20%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.faq-sidebar h2 {
  font-size: 3rem;
  font-weight: bold;
  margin: 0;
  text-align: left;
}

.faq-accordion {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.faq-item {
  border: 1px solid #000;
  border-radius: 5px;
  overflow: hidden;
}

.faq-question {
  padding: 15px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
}

.faq-answer {
  padding: 15px;
  background-color: white;
  border-top: 1px solid #ddd;
  font-size: 0.9rem;
  line-height: 1.6;
  text-align: left;
}

.view-all-btn {
  background-color: black;
  color: white;
  border: 1px solid black; /* Added black border */
  padding: 5px 15px;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  align-self: flex-start;
  margin-top: 10px;
  font-weight: 600;
}

.view-all-btn-container {
  text-align: left;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .faq-accordion {
    text-align: left;
    align-items: center;
  }

  .faq-item {
    width: 100%;
  }

  .faq-container {
    flex-direction: column;
  }

  .faq-sidebar {
    width: 100%;
    margin-bottom: 20px;
  }

  .faq-sidebar h2 {
    font-size: 1.75rem;
    text-align: center;
  }
  .view-all-btn {
    text-align: center;
  }
}
