.how-it-works-demo-grid-section {
  padding: 6rem 0 6rem;
}

.how-it-works-demo-grid-card-body {
  display: flex;
  flex-direction: column;
}

.how-it-works-demo-grid-button-wrapper {
  margin-top: auto;
}

@media (max-width: 768px) {
  .how-it-works-demo-grid-section {
    padding: 4rem 0 4rem;
  }
}
