/* Section styles */

.container.desktop-container {
  max-width: 1200px;
  margin: 0 auto;
}

.vertical-tabs-section-title {
  width: 100%;
  text-align: center;
  font-size: 3rem;
  font-weight: 600;
  padding: 0rem 0rem 2rem;
}

/* Main container for tabs */
.vertical-tabs-container {
  display: flex;
  gap: 2rem; /* Spacing between tabs list and content */
}

/* Tabs list styles */
.vertical-tabs-list {
  display: flex;
  flex-direction: column;
  flex: 1.25;
  border-right: 1px solid #ddd;
  gap: 1rem; /* Adds margin between tab items */
}

/* Individual tab button styles */
.vertical-tabs-button {
  padding: 1rem;
  text-align: left;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 1rem;
  color: #555;
  line-height: 1.4;
  transition: background-color 0.3s, color 0.3s;
  border-radius: 15px;
}

.vertical-tabs-button-active:hover {
  color: #fff; /* Ensures active state styling remains consistent on hover */
  background-color: #000; /* Prevents hover from overriding the active state */
}

.vertical-tabs-button-active {
  font-weight: bold;
  color: #fff;
  background-color: #000;
}

/* Pretitle and title styles */
.vertical-tabs-pretitle {
  display: block;
  font-size: 0.9rem;
  color: #888;
  margin-bottom: 0.5rem;
}

.vertical-tabs-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: inherit;
}

/* Tab content container styles */
.vertical-tabs-content {
  flex: 3; /* Takes up 3x the space of the tabs list */
  display: flex; /* Ensures two-column layout */
  gap: 2rem;
}

/* Tab details (first column) */
.vertical-tabs-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}

/* Header and description styles */
.vertical-tabs-header {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.vertical-tabs-description {
  font-size: 1.15rem;
  line-height: 1.5;
}

/* Image column (second column) */
.vertical-tabs-image-column {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vertical-tabs-image {
  width: 100%;
  max-width: 300px;
  height: auto;
}

.vertical-tabs-badge {
  width: fit-content;
  font-weight: 600;
  font-size: 1rem;
  border-radius: 15px;
  padding: 8px 10px;
}

.vertical-tabs-badge-black {
  background-color: #000 !important;
}

.vertical-tabs-badge-purple {
  background-color: #6f00f9 !important;
}

.vertical-tabs-cta-button {
  width: fit-content;
}

@media (max-width: 768px) {
  .vertical-tabs-section-title {
    font-size: 2rem;
  }
  .vertical-tabs-title {
    font-size: 1rem;
  }
  .vertical-tabs-header {
    font-size: 1.5rem;
  }
  .vertical-tabs-image-column {
    display: none;
  }
  .vertical-tabs-list {
    gap: 0.25rem;
  }
}
