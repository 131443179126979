.thank-you-container {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  background-color: #f9f9f9;
  padding: 20px;
  align-items: flex-start; /* Align the container at the top */
  padding-top: 50px; /* Add padding to create space at the top */
}

.card-container {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adds drop shadow */
  max-width: 600px;
  text-align: center;
}

.thank-you-header {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 40px;
  margin-top: 40px;
  color: #333;
}

.thank-you-subtitle {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 20px;
  line-height: 1.5;
}

.thank-you-graphic img {
  width: 66%; /* Set image width to 2/3 of the content width */
  height: auto;
  margin-top: 20px;
}
