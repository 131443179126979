/* Navbar */
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Space between brand and toggler/nav items */
  padding: 0 15px;
  height: 100px;
  background-color: black; /* Ensure background is visible */
}

.navbar-brand img {
  width: 50px; /* Default logo size */
}

.navbar-nav {
  margin-right: 20px;
  display: flex; /* Ensure nav items display in a row */
  flex-wrap: nowrap; /* Prevent items from wrapping */
}

.navbar .nav-link {
  color: white;
  margin-right: 15px;
  font-weight: 600;
}

.navbar .cta-btn {
  border-radius: 6px;
  margin-left: 20px;
  width: 125px;
}

.bg-black {
  background-color: black;
}

.navbar-toggler {
  display: none; /* Hidden by default (visible only on mobile) */
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3E%3Cpath stroke='%2300f9eb' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  width: 30px;
  height: 30px;
}

/* Mobile styles */
@media (max-width: 992px) {
  .navbar {
    flex-wrap: wrap; /* Allow items to wrap for smaller screens */
    padding: 0 10px; /* Reduce padding for smaller viewports */
  }

  /* Toggler Button */
  .navbar-toggler {
    display: block; /* Ensure the toggler is visible on mobile */
    background: none; /* No background for cleaner look */
    border: none; /* Remove border */
    margin-left: auto; /* Position toggler on the far right */
  }

  .navbar-toggler-icon {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 21px; /* Adjust height to fit three lines */
  }

  .navbar-toggler-icon span {
    display: block;
    height: 3px; /* Thickness of each line */
    background-color: #00f9eb; /* Use primary brand color */
    border-radius: 2px; /* Rounded corners for lines */
  }

  /* Collapsible Navbar */
  .navbar-collapse {
    display: none; /* Hidden by default */
    position: absolute;
    top: 100%;
    left: 0;
    width: 100vw;
    background-color: #343a40;
    z-index: 1;
    padding: 15px 0;
    flex-direction: column; /* Stack nav items vertically */
    align-items: center; /* Center-align links */
  }

  .navbar-collapse.show {
    display: flex; /* Show when toggler is active */
  }

  /* Centering Nav Links */
  .navbar-nav {
    flex-direction: column; /* Stack links vertically */
    align-items: center; /* Center-align nav links */
    gap: 10px; /* Add spacing between links */
    width: 100%;
  }

  .navbar-collapse .nav-link {
    color: white;
    text-align: center; /* Center-align text in links */
    padding: 10px 0;
    width: auto; /* Prevent full-width links */
  }

  .navbar-collapse .cta-btn {
    text-align: center;
    margin: 10px auto; /* Center button in mobile menu */
    width: 125px; /* Make button responsive */
  }
}
