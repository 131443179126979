.feature-cards-section-title {
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .feature-cards-section-title {
    font-size: 1.75rem;
  }
  .feature-section-column {
    padding: 0 2rem;
    max-width: 100%;
  }
  ul.feature-cards-feature-list {
    margin: 3rem 0;
  }
}

.feature-cards-subtitle {
  font-size: 1.25rem;
  font-weight: 500;
  text-align: left;
  margin-bottom: 2rem;
}

.feature-cards-feature-list {
  list-style: none;
  padding: 0;
  margin: 1rem 0;
}

.feature-cards-feature-list li {
  font-size: 1.25rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.feature-cards-check-icon {
  margin-right: 0.5rem;
  font-size: 1.5rem; /* Increase the size of the icon */
}

.feature-cards-card {
  background-color: #ffffff1a;
  border: 1px solid #fff3;
  border-radius: 24px;
  margin-bottom: 24px;
  max-width: 100%;
  padding: 28px;
}

.feature-cards-card-item-top {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  align-items: center;
  margin-bottom: 20px;
  display: flex;
}

.feature-cards-item-title {
  font-size: 1.25rem;
  font-weight: 600;
}

.feature-cards-item-text-wrap {
  width: 100%;
  max-width: 531px;
}

.feature-cards-item-text {
  letter-spacing: -0.01em;
  font-size: 1.15rem; /* 14px */
  line-height: 150%;
}

.feature-cards-badge-transparent {
  background-color: rgba(0, 0, 0, 0.1); /* Transparent background */
  color: inherit; /* Text color for contrast */
  border: 1px solid rgba(255, 255, 255, 0.2); /* Thin border with slight transparency */
  border-radius: 1rem; /* Default Bootstrap badge rounding */
  padding: 8px 20px;
  font-size: 0.875rem; /* Adjust font size if needed */
}
