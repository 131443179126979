/* This will reduce the font size for the entire page */
.small-font {
  font-size: 0.875rem; /* Adjust as needed */
}

.wide-container {
  max-width: 1400px; /* Set a much larger max-width */
  margin: 0 auto; /* Center the container */
  padding: 20px;
}

.job-sessions-page-thead th {
  vertical-align: middle;
}

.job-sessions-page-td-width {
  max-width: 200px; /* Adjust to your desired width */
  word-wrap: break-word; /* Break words if they are too long */
  white-space: normal; /* Allow text to wrap to the next line */
}

.company-assessments-page-td-width {
  max-width: 200px; /* Adjust to your desired width */
  word-wrap: break-word; /* Break words if they are too long */
  white-space: normal; /* Allow text to wrap to the next line */
}

@media (max-width: 768px) {
  /* Override mobile responsiveness */
  .wide-container {
    max-width: 1400px; /* Prevent resizing on smaller screens */
  }
}

.sticky-header thead th {
  position: sticky;
  top: 0;
  background-color: #dbdbdb; /* Adjust background color as needed */
  z-index: 1;
}
