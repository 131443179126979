/* Scoped styles for DemoSignupPage */
.demo-signup-page {
  font-family: Arial, sans-serif;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.demo-signup-background {
  background: radial-gradient(
    circle,
    #007d76 0%,
    rgba(0, 0, 0, 0.8) 50%,
    black 100%
  );
}

/* Content Container */
.demo-content-container {
  display: flex;
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
  gap: 20px; /* Adds spacing between the form and image */
  width: 100%;
  max-width: 600px;
  box-sizing: border-box;
  padding: 3rem 3rem 5rem;
  margin: auto; /* Ensures it's centered horizontally */
}

/* Card Container */
.demo-card-container {
  flex: 2; /* Makes it 2x the size of the image container */
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: none; /* Allow it to grow beyond a fixed width */
  width: 100%;
  box-sizing: border-box;
}

/* Image Container */
.demo-image-container {
  flex: 1; /* 1x grow, 1x shrink, initial size 25% */
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%; /* Ensures responsiveness */
  box-sizing: border-box;
}

.demo-image-container img {
  max-width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 8px; /* Optional: Adds slight rounding to the image corners */
}

/* Navbar */
.demo-navbar {
  display: flex;
  align-items: center;
  background-color: #000;
  height: 80px;
  padding: 0 20px;
  width: 100%;
  position: fixed; /* Ensure navbar stays at the top */
  top: 0;
  left: 0;
  z-index: 1000;
}

.demo-navbar-logo {
  height: 25px;
}

/* Card Styling */
.demo-card {
  background: white;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  padding: 20px;
  text-align: center;
  box-sizing: border-box;
}

.demo-card-header h3 {
  margin: 0 0 10px;
  font-size: 2rem;
  font-weight: 600;
}

.demo-card-header p {
  margin: 0 0 25px;
}

/* Form Styling */
.demo-form-group {
  margin-bottom: 20px;
  text-align: left;
}

.demo-form-group label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

.demo-form-group input,
.demo-form-group select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  height: 42px;
  background-color: white;
  box-sizing: border-box;
}

.demo-form-group select option {
  color: #333;
}

.demo-form-group.inline {
  display: flex;
  gap: 15px;
}

.demo-form-group.inline div {
  flex: 1;
}

/* Submit Button */
.demo-submit-btn {
  width: 100%;
}

/* TOS */
.demo-tos {
  margin-top: 15px;
  font-size: 0.875rem;
  color: #666;
}

.demo-tos a {
  color: #666;
  text-decoration: none;
}

.demo-tos a:hover {
  text-decoration: underline;
}

/* Error Message */
.demo-error-message {
  color: red;
  font-size: 0.875rem;
  margin-bottom: 15px;
}

/* Media Queries */
@media (max-width: 768px) {
  .demo-card {
    width: 100%;
  }

  .demo-card-header h3 {
    font-size: 1.5rem;
  }

  .demo-card-subtitle {
    font-size: 0.9rem;
  }

  .demo-form-group {
    margin-bottom: 20px; /* Spacing between form groups */
  }

  .demo-form-group:last-child {
    margin-bottom: 0; /* Remove spacing after the last group */
  }

  .demo-form-group.inline {
    flex-direction: column; /* Stack inputs in a column */
    gap: 10px; /* Add spacing between inputs within the same group */
  }

  .demo-form-group input,
  .demo-form-group select {
    margin-bottom: 0; /* Reset margin to avoid unnecessary spacing */
  }

  .demo-form-group label {
    font-size: 0.9rem;
  }

  .demo-content-container {
    flex-direction: column; /* Stacks the form and image vertically */
    gap: 20px; /* Adds spacing between stacked items */
  }

  .demo-card-container {
    flex: 1; /* Reset flex for stacked layout */
  }

  .demo-image-container {
    flex: 1; /* Reset flex for stacked layout */
  }

  .demo-tos {
    margin-top: 15px;
    font-size: 0.75rem;
    color: #666;
  }
}
