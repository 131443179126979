/* Hero Section Styles */
.hero-section {
  padding: 4rem 1.25rem;
  position: relative;
}

.hero-section > * {
  position: relative;
  z-index: 2;
}

.hero-section-h1 {
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 2rem;
  text-align: center;
}

.hero-section-h2 {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  padding: 0rem 3rem;
  text-align: center;
}

.hero-section-h1.mobile {
  display: none;
}
.hero-section-h2.mobile {
  display: none;
}

.hero-section-h1.desktop {
  display: block;
}
.hero-section-h2.desktop {
  display: block;
}

@media (max-width: 768px) {
  .hero-section {
    padding: 4rem 1rem 2rem;
  }

  .hero-section-h1 {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 2rem;
  }

  .hero-section-h2 {
    font-size: 1.25rem;
    font-weight: 500;
    padding: 0rem 0.5rem;
  }

  .hero-section-h1.mobile {
    display: block;
  }
  .hero-section-h2.mobile {
    display: block;
  }

  .hero-section-h1.desktop {
    display: none;
  }
  .hero-section-h2.desktop {
    display: none;
  }
}
