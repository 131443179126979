.ai-job-creator-phrase-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ai-job-creator-phrase {
  font-size: 18px;
  margin-right: 5px; /* Space between the phrase and the ellipsis */
  color: #000; /* Ensure text color is visible (adjust to your theme) */
}

.ai-job-creator-ellipsis {
  display: inline-block;
  overflow: hidden;
  vertical-align: bottom;
  width: 1.25em; /* Fixed width for ellipsis container */
  text-align: left; /* Align ellipsis within its container */
  color: #000; /* Set ellipsis color to match phrase (adjust to your theme) */
}

.ai-job-creator-ellipsis::after {
  content: '';
  display: inline-block;
  -webkit-animation: ai-job-ellipsis 900ms steps(4, end) infinite;
  animation: ai-job-ellipsis 900ms steps(4, end) infinite;
}

@keyframes ai-job-ellipsis {
  0% {
    content: '.';
  }
  25% {
    content: '..';
  }
  50% {
    content: '...';
  }
  100% {
    content: '';
  }
}
