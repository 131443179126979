.logo-section {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.logo-section-title {
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 2rem;
}

.logo-row {
  display: flex; /* Use Flexbox for alignment */
  justify-content: space-between; /* Distribute logos evenly */
  align-items: center; /* Align logos vertically */
  max-width: 500px; /* Restrict the width of the row */
  margin: 0 auto; /* Center the row horizontally */
  gap: 0.5rem; /* Add space between logos */
}

.logo-image {
  width: 50px; /* Enforce uniform square dimensions */
  height: 50px;
  object-fit: contain; /* Preserve the logo's aspect ratio */
  display: block; /* Ensure consistent layout */
  transition: transform 0.3s ease-in-out;
  border-radius: 8px;
}

.logo-image:hover {
  transform: scale(1.1); /* Slight zoom effect on hover */
}
