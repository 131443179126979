.feature-benefits-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0rem 2rem;
  width: 100%;
}

.feature-benefits-section.reverse {
  flex-direction: row-reverse; /* Reverse the order of content for alternate layouts */
}

.feature-button-row {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 30px;
  scrollbar-width: none;
  overflow: scroll;
}

.feature-button-badge {
  width: fit-content;
  font-weight: 600;
  font-size: 1rem;
  background-color: #6f00f9 !important;
  border-radius: 15px;
  color: #fff;
  padding: 8px 10px;
}

.feature-button-row .btn {
  margin-right: 10px;
  font-weight: 600;
  border: 2px solid #d6d6d8; /* Updated border color */
  width: fit-content;
  white-space: nowrap; /* Ensures text stays on one line */
}

.feature-button-row .active-feature-btn {
  color: white;
  background-color: #000;
  border-color: #000;
}

.features-section-title {
  width: 100%;
  text-align: center;
  font-size: 3rem;
  font-weight: 600;
  padding: 0rem 0rem 2rem;
}

.features-section-subtitle {
  font-size: 1.25rem;
  text-align: left;
  margin: 0rem;
}

.feature-section-content {
  display: flex;
  justify-content: space-between;
  max-width: 1200px; /* Maintain content width */
  width: 100%;
  gap: 2rem;
}

.feature-section-text-content {
  flex: 0 0 60%; /* Flex-grow: 0, Flex-shrink: 0, Flex-basis: 60% */
  max-width: 60%;
}

.feature-section-image-content {
  flex: 0 0 40%; /* Flex-grow: 0, Flex-shrink: 0, Flex-basis: 40% */
  max-width: 40%;
}

.feature-section-description {
  font-size: 1rem;
  line-height: 1.6;
  color: #555; /* Adjust as needed */
}

.feature-section-image {
  height: 450px;
  border-radius: 8px; /* Rounded corners for static image */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Optional shadow for the image */
  object-fit: cover;
  width: 100%;
}

/* Carousel */
.feature-section-carousel {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 8px; /* Rounded corners for the carousel */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Optional shadow */
}

.feature-section-carousel-item {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px;
  text-align: center;
  background-color: #f9f9f9;
  transition: opacity 0.5s ease-in-out;
}

.feature-section-carousel-item.active {
  display: flex;
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.feature-section-stats-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.feature-section-stats-heading {
  font-size: 5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.feature-section-gradient-text {
  background: linear-gradient(to right, #6a11cb, #2575fc);
  -webkit-background-clip: text;
  color: transparent;
}

.feature-section-percentage {
  font-size: 3rem;
  color: #555;
}

.feature-section-stats-subheading {
  font-size: 1.5rem;
  color: #333;
}

.feature-section-stats-subheading-emphasis {
  font-weight: bold;
  color: #000;
}

/* Feature List */
.feature-section-list {
  list-style: none; /* Remove default bullet points */
  padding: 0; /* Remove padding */
  margin-top: 1.5rem; /* Add spacing above the list */
}

.feature-section-list-item {
  display: flex; /* Use flexbox for horizontal alignment */
  align-items: flex-start; /* Align items at the top */
  margin-bottom: 1rem; /* Spacing between list items */
  font-size: 1rem;
}

.feature-section-icon {
  color: #28a745; /* Green color for the check icon */
  margin-right: 0.5rem; /* Add spacing between the icon and text */
  font-size: 1.5rem; /* Adjust icon size */
  flex-shrink: 0; /* Prevent the icon from shrinking */
}

.feature-section-list-item-text {
  display: flex;
  flex-direction: column; /* Stack emphasis and content */
  text-align: left;
}

.feature-section-list-item-emphasis {
  font-weight: 600;
  margin-bottom: 0.2rem; /* Slight spacing between emphasis and content */
}

.features-section-button-container {
  margin: 2rem 0rem;
  display: flex;
  justify-content: flex-start; /* Align items to the start of the container */
  width: 100%; /* Ensure the container spans the full width */
}

@media (max-width: 768px) {
  .features-section-title {
    font-size: 2rem;
    padding: 0rem 0rem 1rem;
  }

  .feature-section-gradient-text {
    font-size: 3rem;
  }

  .feature-section-percentage {
    font-size: 2rem;
  }

  h3.feature-section-stats-subheading {
    font-size: 1.25rem !important;
  }

  .feature-section-stats-column {
    height: 225px;
  }

  .feature-button-row {
    margin-bottom: 1rem;
    display: none;
  }

  .features-section-button-container {
    margin: 2rem 0rem;
    display: flex;
    justify-content: center; /* Align items to the start of the container */
    width: 100%; /* Ensure the container spans the full width */
  }

  .feature-section-content {
    flex-direction: column;
  }

  .feature-section-title {
    font-size: 1.75rem;
  }

  .features-section-subtitle {
    font-size: 1.25rem;
  }

  .feature-section-title {
    font-size: 2rem;
    margin-top: 40px;
  }

  .feature-section-image {
    height: 300px;
  }

  .feature-section-text-content {
    max-width: 100%;
    padding-right: 0;
  }

  .feature-section-image-content {
    max-width: 100%;
  }
}
