/* Logo Grid Container */
.logo-grid {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(100px, 1fr)
  ); /* Flexible layout */
  gap: 20px; /* Space between items */
  justify-items: center;
  align-items: center;
  margin-top: 1rem;
  width: fit-content;
  display: flex;
}

/* Individual Logo Item */
.logo-grid-item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  border-radius: 8px; /* Rounded corners for logos */
  background-color: #6c757d; /* Light background for contrast */
  transition: transform 0.2s ease-in-out;
}

.logo-grid-item:hover {
  transform: scale(1.05); /* Slight zoom effect on hover */
}

/* Company Logo Styling */
.company-logo {
  max-width: 80px; /* Adjust as needed */
  max-height: 80px;
  width: 36px;
  height: 36px;
  object-fit: contain;
}

@media (max-width: 768px) {
  .logo-grid {
    margin-top: 2rem;
    display: none;
  }
}
