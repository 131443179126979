.small-font {
  font-size: 0.875rem;
}

.tab-font {
  font-size: 0.92rem;
}

/* Change default tab text color */
.nav-tabs .nav-link {
  color: #000; /* Set the color you want for inactive tabs */
}

/* Change active tab text color */
.nav-tabs .nav-link.active {
  color: #000; /* Set the color you want for active tabs */
}

/* Optional: Remove the default Bootstrap blue color on hover */
.nav-tabs .nav-link:hover {
  color: #333; /* Change this to the desired hover color */
}
