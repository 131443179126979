.required-asterisk {
  color: red;
  font-weight: bold;
  margin-left: 4px;
  font-size: 1.2em; /* Adjust the size as needed */
}

.info-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  font-size: 0.8em; /* Adjust the size as needed */
  font-weight: bold;
  color: #fff;
  background-color: #6c757d;
  border-radius: 50%;
  margin-left: 6px;
  cursor: pointer;
}
