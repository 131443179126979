/* Font */
body,
.hero-title,
.homepage-hero-subtitle,
.btn {
  font-family: 'Inter ui', sans-serif;
}

.ig-homepage-logo-link {
  text-decoration: none;
  font-size: 35px;
}

.ig-logo-insta {
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  font-style: italic;
  color: white;
  letter-spacing: 2px;
}

.ig-logo-hear {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-style: italic;
  color: #00f9eb;
  letter-spacing: 2px;
}

.btn-ig-blue {
  background-color: #00f9eb;
  color: black;
  font-weight: 700;
  padding: 10px 20px;
  border-radius: 6px;
  --bs-btn-color: #fff;
  --bs-btn-bg: #00f9eb;
  --bs-btn-border-color: #00f9eb;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #00b3a9;
  --bs-btn-hover-border-color: #00b3a9;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #00b3a9;
  --bs-btn-active-border-color: #00b3a9;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #02716b;
  --bs-btn-disabled-border-color: #02716b;
}

.btn-ig-inverseblue {
  background-color: #00b3a9;
  color: white;
  font-weight: 700;
  padding: 10px 20px;
  border-radius: 6px;
  --bs-btn-color: #fff;
  --bs-btn-bg: #00b3a9;
  --bs-btn-border-color: #00b3a9;
  --bs-btn-hover-color: black;
  --bs-btn-hover-bg: #00f9eb;
  --bs-btn-hover-border-color: #00f9eb;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: black;
  --bs-btn-active-bg: #00f9eb;
  --bs-btn-active-border-color: #00f9eb;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #02716b;
  --bs-btn-disabled-border-color: #02716b;
}

.btn-ig-purple {
  background-color: #6f00f9;
  color: white;
  font-weight: 700;
  padding: 10px 20px;
  border-radius: 6px;
  --bs-btn-color: #fff;
  --bs-btn-bg: #6f00f9;
  --bs-btn-border-color: #6f00f9;
  --bs-btn-hover-color: white;
  --bs-btn-hover-bg: #8b2dff;
  --bs-btn-hover-border-color: #8b2dff;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: white;
  --bs-btn-active-bg: #8b2dff;
  --bs-btn-active-border-color: #8b2dff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #633896;
  --bs-btn-disabled-border-color: #633896;
}

.homepage-container {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.homepage-hero-section {
  padding: 5rem 0;
  max-height: 70vh;
}

.homepage-hero-content {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.homepage-hero-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.homepage-hero-image img {
  max-width: 85%;
  height: auto;
  border-radius: 8px;
}

@media (max-width: 768px) {
  .hero-cta-link {
    margin: auto;
  }

  .homepage-container {
    margin-top: 80px;
  }

  .homepage-hero-section .row {
    flex-direction: column;
  }

  .homepage-hero-section {
    max-height: none;
    padding: 3rem 1rem;
  }

  .homepage-hero-content,
  .homepage-hero-image {
    text-align: center;
    margin-top: 1rem;
  }

  .homepage-hero-content {
    margin-bottom: 20px;
  }

  .hero-title {
    font-size: 2.4rem !important;
    line-height: 1.2;
    word-wrap: break-word;
    margin-bottom: 20px;
  }

  p.homepage-hero-subtitle {
    font-size: 1.25rem;
  }
}

.get-demo-form {
  margin-top: 20px;
  display: flex;
  align-items: center;
  width: 100%;
}

.demo-input {
  flex: 1;
  margin-right: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  height: 48px;
  line-height: normal;
}

.demo-button {
  white-space: nowrap;
  padding: 10px 20px;
  font-weight: 700;
  border-radius: 4px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: normal;
}

.hero-title {
  font-size: 48px;
  font-weight: 700;
  line-height: 125%;
  margin-top: 0;
  margin-bottom: 30px;
}

.homepage-hero-subtitle {
  margin-right: auto;
  margin-left: auto;
  font-size: 24px;
  line-height: 145%;
  margin-bottom: 50px;
}

.hero-btn {
  margin-right: auto;
  margin-left: auto;
  background-color: #007bff;
  color: white;
  font-weight: 700;
  padding: 10px 20px;
  border-radius: 6px;
}

.hero-btn:hover {
  background-color: #0056b3;
  box-shadow: 0 4px 10px 4px rgba(41, 59, 155, 0.1);
}

.desktop-container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.about-us,
.features,
.contact-us {
  background-color: #f9f9f9;
  text-align: center;
  font-size: 20px;
}

section.faq {
  background-color: #eaedff;
  text-align: center;
  font-size: 20px;
  padding-top: 5rem;
  padding-bottom: 6rem;
}

section.about-us {
  background-color: #eaedff;
}

.homepage-section-title {
  text-align: center;
  font-weight: 700;
  margin-bottom: 60px !important;
}

.homepage-section-title.how-it-works-title {
  margin-top: 120px;
}

.feature-box {
  text-align: center;
}

.contact-us .form-control {
  font-size: 16px;
}

.homepage-icon {
  height: 100px;
}
