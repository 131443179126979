.banner-section {
  padding-top: 5rem;
  padding-bottom: 5rem;
  text-align: center; /* Center-align content by default */
}

@media (max-width: 768px) {
  .banner-section {
    padding: 4rem 1rem 2rem;
  }
}

.banner-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.banner-subtitle {
  font-size: 1.25rem;
  margin-bottom: 2rem;
}

.banner-button {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.banner-button:hover {
  opacity: 0.9;
}
