.money-back-guarantee-image {
  width: 100px;
  height: 100px;
}

.pricing-page-free-trial-card-section {
  background-color: #ffffffa6;
  border: 1px solid #fff3;
  border-radius: 5px;
  margin-bottom: 24px;
  max-width: 100%;
  padding: 38px 40px 10px;
}

@media (max-width: 768px) {
  .pricing-card-features p {
    font-size: 0.95rem;
  }
  .money-back-guarantee-text {
    font-size: 0.8rem !important;
  }
  .money-back-guarantee-image {
    width: 80px;
    height: 80px;
  }
  .pricing-page-free-trial-card-section {
    padding: 30px 28px;
  }
  .pricing-page-free-trial-feature-item {
    font-size: 0.95rem;
  }
  .pricing-page-free-trial-card-title {
    font-size: 1.25rem;
  }
}
